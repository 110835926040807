import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Heading from '../shared/heading'

const Container = styled.section`
  width: 100%;
  padding-top: 4rem;
  height: 100%;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  max-width: 980px;
  margin: 0 auto;

  @media (min-width: 640px) {
    height: 500px;
  }
`

const Married = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Rings = styled(Img)`
  height: 200px;
  width: 200px;
`

const Banner: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      kilkenny: file(relativePath: { eq: "icon-color.png" }) {
        ...smallImage
      }
    }
  `)
  return (
    <Container>
      <Married>
        <Rings fluid={data.kilkenny.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} alt="rings" />
      </Married>
      <Heading id={'rsvp_information'} />
    </Container>
  )
}

export default Banner
