import React, { HTMLAttributes } from 'react'
import { Form, Formik, FormikActions, FormikProps, Field, FieldProps, ErrorMessage } from 'formik'
import styled from 'styled-components'
import * as Yup from 'yup'
import qs from 'qs'
import Cookie from 'js-cookie'
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl'
import { navigate } from 'gatsby'
import { lighten } from 'polished'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #6fd8b2;
  border: 1px solid #000025;
  margin: 5rem auto;
  width: 100%;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    border: 1px solid #000025;
    height: 100%;
    width: 100%;
    -webkit-transform: translate(0.5em, 0.5em);
    transform: translate(0.5em, 0.5em);
    pointer-events: none;
  }

  @media (min-width: 640px) {
    width: 50%;
    min-width: 550px;
  }

  @media (min-width: 1024px) {
    width: 40%;
    max-width: 580px;
    min-width: 550px;
  }
`

const Content = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: center;
  align-items: center;

  @media (min-width: 640px) {
    width: 50%;
    min-width: 550px;
  }

  @media (min-width: 1024px) {
    width: 40%;
    max-width: 580px;
    min-width: 550px;
  }
`

const Header = styled.header`
  text-align: center;
  border-bottom: 1px solid #000025;
  width: 100%;
  padding: 1.8rem 1.5rem 1.3rem;
`

const Headline = styled.h1`
  color: #153048;
  font-size: 1.4rem;
  letter-spacing: 0.13rem;
  margin: 0 0 0.1rem;
  text-transform: uppercase;
`

const FormWrapper = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Label = styled.label`
  font-family: Heebo, sans-serif;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  font-size: 0.88rem;
  font-weight: 500;
  letter-spacing: 0.08rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  line-height: 1.6rem;
`

interface LabelProps extends HTMLAttributes<HTMLElement> {
  border?: string
}

const Input = styled.input<LabelProps>`
  font-family: Lato, sans-serif;
  border: ${props => props.border || '1px solid #000025'};
  background-color: #fff;
  color: #000025;
  line-height: 1rem;
  padding: 5px 10px;
  width: 100%;
  font-size: 0.875rem;
`

const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`

interface SelectProps extends HTMLAttributes<HTMLElement> {
  border?: string
}
const Select = styled.select<SelectProps>`
  border: ${props => props.border || '1px solid #8a97a3'};
  background-color: #fff;
  color: #000025;
  line-height: 1rem;
  padding: 5px 10px;
  width: 100%;
  font-size: 0.875rem;
  //font-family: Lato, 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  display: inline-block;
  cursor: pointer;
  outline: 0;
  border: 1px solid #000025;
  border-radius: 0px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &::-ms-expand {
    display: none;
  }
  &:hover,
  &:focus {
    color: #000025;
    background: #ffffff;
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

const Arrow = styled.div`
  position: absolute;
  top: 9px;
  right: 15px;
  width: 0;
  height: 0;
  border: solid #000025;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);

  &:hover ~ .select_arrow,
  &:focus ~ .select_arrow {
    border-color: #000000;
  }
  &:disabled ~ .select_arrow {
    border-top-color: #cccccc;
  }
`

const Button = styled.button`
  background-color: #000025;
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-size: 0.88rem;
  font-weight: 700;
  letter-spacing: 0.08rem;
  padding: 1rem 1.5rem;
  text-transform: uppercase;
  margin: 0 auto;

  &:hover {
    background-color: ${lighten(0.1, '#000025')};
  }
`

const Error = styled.span`
  color: red;
  text-align: right;
  font-size: 0.6rem;
`

const Footer = styled.footer`
  border-top: 1px solid #153048;
  padding: 1.5rem;
  text-align: center;
  width: 100%;
`

interface FormValuesProps {
  firstName: string
  lastName: string
  email: string
  street: string
  postcode: string
  city: string
  country: string
  dietary: string
  attend: number | undefined
  'bot-field': string
  'form-name': string
}

const getValidationSchema = (intl: InjectedIntl) => {
  return Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'too Short!')
      .max(50, 'too Long!')
      .required(intl.formatMessage({ id: 'required' })),
    lastName: Yup.string()
      .min(2, 'too Short!')
      .max(50, 'too Long!')
      .required(intl.formatMessage({ id: 'required' })),
    email: Yup.string()
      .email('Invalid email')
      .required(intl.formatMessage({ id: 'required' })),
    street: Yup.string()
      .min(2, 'too Short!')
      .max(50, 'too Long!')
      .required(intl.formatMessage({ id: 'required' })),
    postcode: Yup.string()
      .min(3, 'is this a post code?')
      .max(10, 'is this a post code?')
      .required(intl.formatMessage({ id: 'required' })),
    city: Yup.string()
      .min(2, 'where is that?')
      .max(50, 'that a long name for a city')
      .required(intl.formatMessage({ id: 'required' })),
    country: Yup.string()
      .min(2, 'too short please insert your country.')
      .max(50, 'where are you living?')
      .required(intl.formatMessage({ id: 'required' })),
    attend: Yup.number()
      .min(1, 'are you sure you not coming :(')
      .moreThan(0, 'are you sure you not coming :(')
      .max(5, 'more then 5 people wow, split the group')
      .required(intl.formatMessage({ id: 'required' })),
  })
}

interface Props {
  intl: InjectedIntl
}

const Forms: React.FC<Props> = ({ intl }) => {
  return (
    <Container>
      <Header>
        <Headline>
          <FormattedMessage id="rsvp" />
        </Headline>
      </Header>
      <Formik
        initialValues={{
          'bot-field': '',
          'form-name': 'rsvp',
          firstName: '',
          lastName: '',
          email: '',
          street: '',
          postcode: '',
          city: '',
          country: '',
          dietary: '',
          attend: 0,
        }}
        validationSchema={() => getValidationSchema(intl)}
        onSubmit={(values: FormValuesProps, actions: FormikActions<FormValuesProps>) => {
          fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: qs.stringify({
              ...values,
            }),
          })
            .then(value => {
              actions.setSubmitting(false)
              if (typeof window !== 'undefined') {
                value !== undefined ? Cookie.set('wedding-token', value) : null
                navigate('/thanks', { state: { ...value } })
              }
            })
            .catch(err => {
              console.warn('Error: Please Try Again!', err)
              actions.setSubmitting(false)
            })
        }}
        render={(formikBag: FormikProps<FormValuesProps>) => (
          <FormWrapper
            name="rsvp"
            method="post"
            action="/thanks"
            noValidate
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <Content>
              <Field type="hidden" name="form-name" />
              <Field type="hidden" name="bot-field" />
              <Field
                name="firstName"
                type="text"
                render={({ field, form }: FieldProps<FormValuesProps>) => (
                  <Label htmlFor="dietary">
                    <FormattedMessage id="name" />
                    <Input type="text" name="firstName" border={form.errors.firstName && '1px solid red'} {...field} />
                    {form.touched.firstName && form.errors.firstName && (
                      <Error>
                        <ErrorMessage name="firstName" />
                      </Error>
                    )}
                  </Label>
                )}
              />
              <Field
                name="lastName"
                type="text"
                render={({ field, form }: FieldProps<FormValuesProps>) => (
                  <Label htmlFor="lastName">
                    <FormattedMessage id="surename" />
                    <Input type="text" name="lastName" border={form.errors.lastName && '1px solid red'} {...field} />
                    {form.touched.lastName && form.errors.lastName && (
                      <Error>
                        <ErrorMessage name="lastName" />
                      </Error>
                    )}
                  </Label>
                )}
              />
              <Field
                name="email"
                type="email"
                render={({ field, form }: FieldProps<FormValuesProps>) => (
                  <Label htmlFor="email">
                    <FormattedMessage id="email" />
                    <Input type="email" name="email" border={form.errors.email && '1px solid red'} {...field} />
                    {form.touched.email && form.errors.email && (
                      <Error>
                        <ErrorMessage name="email" />
                      </Error>
                    )}
                  </Label>
                )}
              />
              <Field
                name="street"
                type="text"
                render={({ field, form }: FieldProps<FormValuesProps>) => (
                  <Label htmlFor="street">
                    <FormattedMessage id="street" />
                    <Input type="text" name="street" border={form.errors.street && '1px solid red'} {...field} />
                    {form.touched.street && form.errors.street && (
                      <Error>
                        <ErrorMessage name="street" />
                      </Error>
                    )}
                  </Label>
                )}
              />
              <Field
                name="postcode"
                type="text"
                render={({ field, form }: FieldProps<FormValuesProps>) => (
                  <Label htmlFor="postcode">
                    <FormattedMessage id="postcode" />
                    <Input type="text" name="postcode" border={form.errors.postcode && '1px solid red'} {...field} />
                    {form.touched.postcode && form.errors.postcode && (
                      <Error>
                        <ErrorMessage name="postcode" />
                      </Error>
                    )}
                  </Label>
                )}
              />
              <Field
                name="city"
                type="text"
                render={({ field, form }: FieldProps<FormValuesProps>) => (
                  <Label htmlFor="city">
                    <FormattedMessage id="city" />
                    <Input name="city" type="text" border={form.errors.city && '1px solid red'} {...field} />
                    {form.touched.city && form.errors.city && (
                      <Error>
                        <ErrorMessage name="city" />
                      </Error>
                    )}
                  </Label>
                )}
              />
              <Field
                name="country"
                type="text"
                render={({ field, form }: FieldProps<FormValuesProps>) => (
                  <Label htmlFor="country">
                    <FormattedMessage id="country" />
                    <Input name="country" type="text" border={form.errors.country && '1px solid red'} {...field} />
                    {form.touched.country && form.errors.country && (
                      <Error>
                        <ErrorMessage name="country" />
                      </Error>
                    )}
                  </Label>
                )}
              />

              <Field
                name="attend"
                type="text"
                render={({ field, form }: FieldProps<FormValuesProps>) => (
                  <Label htmlFor="attend">
                    <FormattedMessage id="attend" />
                    <SelectWrapper>
                      <Select name="attend" {...field}>
                        <option value={undefined} label="-------">
                          -------
                        </option>
                        <option value={1} label="1">
                          1
                        </option>
                        <option value={2} label="2">
                          2
                        </option>
                        <option value={3} label="3">
                          3
                        </option>
                        <option value={4} label="4">
                          4
                        </option>
                        <option value={5} label="5">
                          5
                        </option>
                      </Select>
                      <Arrow />
                    </SelectWrapper>
                    {form.touched.attend && form.errors.attend && (
                      <Error>
                        <ErrorMessage name="attend" />
                      </Error>
                    )}
                  </Label>
                )}
              />

              <Field
                name="dietary"
                type="text"
                render={({ field, form }: FieldProps<FormValuesProps>) => (
                  <Label htmlFor="dietary">
                    <FormattedMessage id="dietary" />
                    {form.touched.dietary && form.errors.dietary && form.errors.dietary && (
                      <span color="red">{form.errors.dietary}</span>
                    )}
                    <Input
                      type="text"
                      name="dietary"
                      placeholder={intl.formatMessage({ id: 'dietary_placeholder' })}
                      border={form.errors.dietary && '1px solid red'}
                      {...field}
                    />
                  </Label>
                )}
              />
            </Content>
            <Footer>
              <Button type="submit" disabled={formikBag.isSubmitting}>
                <FormattedMessage id="submit" />
              </Button>
            </Footer>
          </FormWrapper>
        )}
      />
    </Container>
  )
}

export default injectIntl(Forms)
